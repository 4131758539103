.App {
  text-align: center;
  font-family: 'Varela Round', sans-serif;
}

.ais-SearchBox {
  margin: 1em 0;
  ;
}

.ais-Pagination {
  margin-top: 1em;
}

.ais-InstantSearch {
  max-width: 960px;
  overflow: hidden;
  margin: 0 auto;
}

.ais-Hits-item {
  margin-bottom: 0.2em;
  border-radius: 10px;
  width: 100%
}

.ais-SearchBox-submitIcon path,
.ais-SearchBox-resetIcon path {
  display: none;
  outline: none;

}

.ais-Hits-item img {
  margin-right: 1em;
}

.hit-name {
  margin-bottom: 0.5em;
}

.hit-description {
  color: #888;
  font-size: 14px;
  margin-bottom: 0.5em;
}


.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.next-button,
.done-button {
  width: 250px;
  height: 50px;
  border-radius: 25px !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  font-family: 'Varela Round', sans-serif !important;
  -webkit-box-shadow: 0px 0px 25px -8px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 25px -8px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 25px -8px rgba(0, 0, 0, 0.5);
  background: rgb(191, 219, 223);
  background: -moz-linear-gradient(left, rgba(3, 229, 254, 1) 0%, rgba(73, 155, 234, 1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(3, 229, 254, 1)), color-stop(100%, rgba(73, 155, 234, 1)));
  background: -webkit-linear-gradient(left, rgba(3, 229, 254, 1) 0%, rgba(73, 155, 234, 1) 100%);
  background: -o-linear-gradient(left, rgba(3, 229, 254, 1) 0%, rgba(73, 155, 234, 1) 100%);
  background: -ms-linear-gradient(left, rgba(3, 229, 254, 1) 0%, rgba(73, 155, 234, 1) 100%);
  background: linear-gradient(to right, rgba(3, 229, 254, 1) 0%, rgba(73, 155, 234, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#03e5fe', endColorstr='#499bea', GradientType=1);
}

.add-button {
  padding-left: 16px;
  padding-right: 16px;
  height: 40px;
  margin-right: 30px;
  border-radius: 20px !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  -webkit-box-shadow: 0px 0px 25px -8px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 25px -8px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 25px -8px rgba(0, 0, 0, 0.5);
  background: rgb(191, 219, 223);
  background: -moz-linear-gradient(left, rgba(3, 229, 254, 1) 0%, rgba(73, 155, 234, 1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(3, 229, 254, 1)), color-stop(100%, rgba(73, 155, 234, 1)));
  background: -webkit-linear-gradient(left, rgba(3, 229, 254, 1) 0%, rgba(73, 155, 234, 1) 100%);
  background: -o-linear-gradient(left, rgba(3, 229, 254, 1) 0%, rgba(73, 155, 234, 1) 100%);
  background: -ms-linear-gradient(left, rgba(3, 229, 254, 1) 0%, rgba(73, 155, 234, 1) 100%);
  background: linear-gradient(to right, rgba(3, 229, 254, 1) 0%, rgba(73, 155, 234, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#03e5fe', endColorstr='#499bea', GradientType=1);
}

.ant-picker-dropdown {
  /* top: 1100px !important; */
  /* top: calc(50vh) !important;
  left: calc(50vw) !important; */
}

.textinput {
  width: 100%;
  padding: 10px;
  height: 50px;
  color: rgb(90, 88, 110);
  font-size: 16px;
  text-align: right;
  border-radius: 25px;
  border: 1px solid #438BEC;
  direction: rtl;
  /* border: none; */
  /* background-color: rgb(246,247,250); */
  background-color: none;
  font-family: 'Varela Round', sans-serif;
  outline: none;
}

.textarea_box {
  width: 100%;
  padding: 10px;
  color: rgb(90, 88, 110);
  font-size: 16px;
  text-align: right;
  border-radius: 25px;
  border: 1px solid #438BEC;
  direction: rtl;
  /* border: none; */
  /* background-color: rgb(246,247,250); */
  background-color: none;
  font-family: 'Varela Round', sans-serif;
  outline: none;
}

body {
  background-color: rgba(245, 245, 251, 1)
}

.icon {
  -webkit-filter: opacity(.4) drop-shadow(255 255 255 white);
  filter: opacity(1.0) drop-shadow(255 255 255 white);
}

.menuButton {
  width: 100%;
  height: 40;
  color: rgb(230, 230, 230);
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inlineBlockContainter {
  display: flex;
  align-items: center;
}

.menuButton:hover {
  color: white;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.06)
}

.footerButton {}

.pageBody {
  height: 100%;
  margin: 0;
}

.pageWrapper {
  min-height: calc(100vh - 40px);

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -50px;
}

.pageFooter,
.pagePush {
  height: 50px;
}

.footerButton:hover {
  color: rgb(113, 124, 245);
  cursor: pointer;
}

.collapsible {
  padding: 18px;
  width: 100%;
  border: none;
  background-color: none;
  text-align: left;
  outline: none;
  cursor: pointer;
}

.titleLink {}

.titleLink:hover {
  color: rgb(113, 124, 245);
  cursor: pointer;
  text-decoration: underline;
}

.scrollmenu {
  margin-top: 10px;
  background-color: clear;
  overflow: auto;
  white-space: nowrap;
}

.scrollmenu2 {
  background-color: clear;
  overflow: auto;
  white-space: nowrap;
}

.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.scrollmenu2 a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.scrollmenu a:hover {
  background-color: #777;
}

::-webkit-scrollbar {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  min-height: 100vh;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -120px;
}

.footer,
.push {
  height: 50px;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(40, 40, 40, 1.0)); */
  /* background: -webkit-radial-gradient(rgba(40, 40, 40,.9), rgba(40, 40, 40,.9)); */
}